import "./layout.css"
import React from "react"
import Header from "../header/header"
import { Outlet } from "react-router-dom"

export const Layout = ({ className = "" }) => (
  <div className={`${className}  bg-black text-white min-h-screen`}>
    <Header className="Layout_header" />
    <div className="mainContainer">
      <Outlet />
    </div>
  </div>
)

export default Layout;