import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from './components/layout/layout';
import HomePage from './components/home-page/home-page';
import LinksPage from './components/links-page/links-page';
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path="/links" element={<LinksPage />} />
        </Route>
        
      </Routes>
    </Router>
  );
}
export default App;
