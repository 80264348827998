import "./links-list.css";
import React from "react";
import StripchatPng from "../../assets/icons/stripchat.png";
import ManyvidsPng from "../../assets/icons/manyvids.png";
import XPng from "../../assets/icons/x.png";
import InstagramPng from "../../assets/icons/instagram.png";

export interface LinksListProps {
  className?: string;
}

export const LinksList: React.FC<LinksListProps> = ({ className = "" }) => (
  <div className={`${className} linksListWrapper`}>
    <div className="linkListBox">
      <div className="linkListSitebox">
        <div className="linkListIcon">
          <img src={StripchatPng} alt="" className="LinksList_img1" />
        </div>
        <div className="linkListSitename">Stripchat</div>
      </div>
      <div className="linkListMylink">
        <a
          href="https://stripchat.com/HarleyJayde"
          target="_blank"
          className="LinksList_a1"
          rel="noreferrer"
        >
          stripchat.com/HarleyJayde
        </a>
      </div>
    </div>
    <div className="linkListBox">
      <div className="linkListSitebox">
        <div className="linkListIcon">
          <img src={ManyvidsPng} alt="" className="LinksList_img1" />
        </div>
        <div className="linkListSitename">ManyVids</div>
      </div>
      <div className="linkListMylink">
        <a
          href="https://Missharleyjxyde.manyvids.com"
          target="_blank"
          className="LinksList_a1"
          rel="noreferrer"
        >
          Missharleyjxyde.manyvids.com
        </a>
      </div>
    </div>
    <div className="linkListBox">
      <div className="linkListSitebox">
        <div className="linkListIcon">
          <img src={XPng} alt="" className="LinksList_img1" />
        </div>
        <div className="linkListSitename">Twitter / X</div>
      </div>
      <div className="linkListMylink">
        <a
          href="https://x.com/missharleyjxyde"
          target="_blank"
          className="LinksList_a1"
          rel="noreferrer"
        >
          x.com/missharleyjxyde
        </a>
      </div>
    </div>
    <div className="linkListBox">
      <div className="linkListSitebox">
        <div className="linkListIcon">
          <img src={InstagramPng} alt="" className="LinksList_img1" />
        </div>
        <div className="linkListSitename">Instagram</div>
      </div>
      <div className="linkListMylink">
        <a href="https://instagram.com/spookybabe_jxyde" target="_blank" rel="noreferrer" className="LinksList_a1">
          instagram.com/spookybabe_jxyde
        </a>
      </div>
    </div>
  </div>
);
