import "./header.css";
import React from "react";
export interface HeaderProps {
  className?: string;
}
export const Header = ({ className = "" }) => (
  <div className="HeaderWrapper">
    <div className={`${className} HeaderContent`}>
      <div
        style={{
          fontFamily: "AngerStyles, sans-serif",
          fontSize: "48px",
          marginTop: "8px",
          marginBottom: "8px",
          fontWeight: "800",
          color: "#ffaa00",
        }}
      >
        Harley Jayde
      </div>
    </div>
  </div>
);
export default Header;
