import "./links-page.css";
import React from "react";
import { LinksList } from "../links-list/links-list";

export interface LinksPageProps {
  className?: string;
}

export const LinksPage: React.FC<LinksPageProps> = ({ className = "" }) => (
  <div className="linksPageWrapper">
    <div className={`${className} linksPageTop`}>Official Links</div>
    <div className="linksPageNotice">
      Beware of fakes/scams. These are my official links.
    </div>
    <div className="linkListMylink">
      <LinksList />
    </div>
  </div>
);

export default LinksPage;
