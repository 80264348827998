import "./home-page.css";
import React from "react";
import HerodarkWebp from "../../assets/images/herodark.webp";
import { LinksPage } from "../links-page/links-page";
export interface HomePageProps {
  className?: string;
}
export const HomePage: React.FC<HomePageProps> = ({ className = "" }) => (
  <div className={`${className} heroImageWrapper`}>
    <img src={HerodarkWebp} alt="" className="HomePage_img1" />
    <div>
      <LinksPage />
    </div>
    <div className="HomePage_div1">
      &copy; Copyright 2024 Vicarious Studio / Harley Jayde<br/>
      All Rights Reserved
    </div>
  </div>
);
export default HomePage;
